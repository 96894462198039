@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url(""); */

* {
    scroll-behavior: smooth;
}

 :root {
  --primary-color: #d2232a;
  --theme-lebanon: #c80f1e;
  --theme-لبنان: #c80f1e;
  --theme-sports: #1e7875;
  --theme-رياضة: #1e7875;
  --theme-arab: #929667;
  --theme-عرب: #929667;
  --theme-writer: #766e60;
  --theme-opinion: #c3a847;
  --theme-opinion-caricature: #c3a847;
  --theme-رأي: #c3a847;
  --theme-قضايا-وآراء: #c3a847;
  --theme-people: #9e005d;
  --theme-life: #9e005d;
  --theme-حياة-وناس: #9e005d;
  --theme-comics: #9e005d;
  --theme-culture: #c95e17;
  --theme-ثقافة: #c95e17;
  --theme-world: #617884;
  --theme-عالم: #617884;
  --theme-ملاحق: #702211;
  --theme-supplements: #702211;
  --theme-المزيد: #000;
}

.border-lebanon {
    border-color: var(--theme-lebanon);
}
.color-lebanon {
    color: var(--theme-lebanon);
}
.border-sports {
    border-color: var(--theme-sports);
}
.color-sports {
    color: var(--theme-sports);
}
.border-arab {
    border-color: var(--theme-arab);
}
.color-arab {
    color: var(--theme-arab);
}
.border-writer {
    border-color: var(--theme-writer);
}
.color-writer {
    color: var(--theme-writer);
}
.border-opinion {
    border-color: var(--theme-opinion);
}
.color-opinion {
    color: var(--theme-opinion);
}
.border-people {
    border-color: var(--theme-people);
}
.color-people {
    color: var(--theme-people);
}
.border-culture {
    border-color: var(--theme-culture);
}
.color-culture {
    color: var(--theme-culture);
}
.border-world {
    border-color: var(--theme-world);
}
.color-world {
    color: var(--theme-world);
}
.border-supplements {
    border-color: var(--theme-supplements);
}
.color-supplements {
    color: var(--theme-supplements);
}
@font-face {
    font-family: "Amine Bold";
    src: url("/fonts/AmineBold.otf") format("woff");
    font-display: swap;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "Amine Light";
    font-display: swap;
    font-weight: 200;
    src: url("/fonts/AmineLight.otf") format("woff");
}
.font-amine-light {
    font-weight: 200;
}
@font-face {
    font-family: "Greta Light";
    font-display: swap;
    src: url("/fonts/GretaLight.ttf") format("woff");
}

html {
    /* background-color: #f2f3f2; */
}

body {
    direction: rtl;
    text-align: right;
    font-family: Amine Bold, sans-serif;
}
section ::-webkit-scrollbar,
.hide-scroll ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scrollbar-height: none;
}
.hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 6px;
}
section .urgent-widget,
.searchR {
    scrollbar-width: 8px;
}

section .urgent-widget ::-webkit-scrollbar,
.searchR ::-webkit-scrollbar {
    width: 5px;
    display: block;
}

section .urgent-widget ::-webkit-scrollbar-thumb,
.searchR ::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 15px;
}
.post-details-content table {
    border-collapse: collapse;
    width: 100%;
}

.post-details-content table td {
    border: 1px solid #ddd;
}

ul.slick-dots {
    bottom: 20px;
}
.slick-track {
    cursor: grab;
}
.slick-track .slick-slide * {
    outline: 0 !important;
    box-shadow: none !important;
    border-radius: 0.5rem;
}
.slick-dots li button:before {
    width: 20px !important;
    height: 20px !important;
    font-size: 15px !important;
    color: white !important;
    opacity: 0.75 !important;
}
.slick-dots li.slick-active button:before {
    opacity: 0.9 !important;
    color: var(--custom-pink) !important;
}
.slick-dots li button {
    font-size: 0px !important;
}
.slick-prev:before,
.slick-next:before {
    content: unset !important;
}
.post-details-content iframe {
    display: block;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.post-details-content blockquote {
    padding: 22px 10px;
    position: relative;
    border-radius: 8px;
    border: 0;
    margin-top: 35px;
    margin-bottom: 35px;
    font-family: "Amine Bold";
    font-size: 20px;
    color: #010705;
}
.left-border {
    position: relative;
}
.left-border:before {
    content: "";
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 2px;
    height: 70%;
    transform: translateY(50%);
    background: #908d8d;
}
@media screen and (max-width: 998px) {
    .left-border-mobile {
        position: relative;
    }
    .left-border-mobile:before {
        content: "";
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 2px;
        height: 70%;
        transform: translateY(50%);
        background: #c80f1e;
    }
}

@media screen and (min-width: 998px) {
  .post-details-content blockquote:not(.text-post-media):not(.instagram-media):not([data-text-post-permalink^="https://www.threads.net/"]):not(.twitter-tweet):not(.tiktok-embed)
  {
    position: absolute;
    max-width: 265px;
    padding: 30px 7px;
    right: 0;
  }

    .post-details-content blockquote:after,
    .post-details-content blockquote:before {
        width: 75px;
        height: 75px;
    }
    .post-details-content .text-post-media:after,
    .post-details-content .text-post-media:before,
    .post-details-content .instagram-media:before,
    .post-details-content .instagram-media:after {
        content: unset;
    }

    .post-details-content blockquote span,
    .post-details-content blockquote p,
    .post-details-content blockquote {
        font-size: 23px !important;
    }
}
.post-details-content blockquote span,
.post-details-content blockquote p {
  font-size: 20px;
  color: #010705;
  z-index: 1;
  position: relative;
  font-family: "Amine Bold";
  text-align: right !important;
}
.post-details-content blockquote:not(.tiktok-embed):not([data-text-post-permalink^="https://www.threads.net/"]):after
{
  content: "";
  position: absolute;
  z-index: 0;
  top: -22px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-image: url("/images/quote-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.post-details-content blockquote:not(.tiktok-embed):not([data-text-post-permalink^="https://www.threads.net/"]):before
{
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -28px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-image: url("/images/quote-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.post-details-content p {
  /* margin-bottom: 10px !important;
  margin-top: 10px !important; */
  text-align: justify;
  line-height: 30px;
}
.post-details-content a {
    color: var(--primary-color);
}
.post-details-content h3 {
    margin-bottom: 10px;
    margin-top: 10px;
}
/* .post-details-content iframe[src*=facebook] {
  width: unset!important
} */

/* .post-details-content iframe[src*="youtube"] {
  height: calc(55vw * 0.6);
} */
@media screen and (max-width: 767px) {
    /* .post-details-content iframe[src*="youtube"] {
    height: calc(55vw * 1.2);
  } */
    .container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .searchR ::-webkit-scrollbar,
    .searchR {
        -ms-overflow-style: none;
        scrollbar-width: none;
        scrollbar-height: none;
    }
}
@media (min-width: 750px) {
    .post-details-content img {
        max-width: 100%;
        width: 100%;
    }
    .post-details-content iframe:not(iframe[src*="facebook"]) {
        max-width: 100%;
    }
}
header .menu-item.active a::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 4px;
    background-color: var(--primary-color);
    opacity: 1;
    visibility: visible;
}
header .menu-item.active div a::before {
    content: unset;
}
[class*="group\/item"]:hover svg {
    /* fill: var(--primary-color); */
    transform: rotate(180deg);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
[class*="group\/item"]:hover [class*="sub-menu-group"] {
    opacity: 100;
    display: flex;
}
/* [class*="group\/item"]:hover [class*="menu-item-title"] {
  color: var(--primary-color);
  text-decoration-line: underline;
} */

/* @for $i from 0 through 20 {
  [class*="show-sub-menu-#{$i}"] [class*="sub-menu-group"] {
    display: flex;
  }
} */
@media (max-width: 767px) {
  .post-details-content blockquote:not(.tiktok-embed):not([data-text-post-permalink^="https://www.threads.net/"]) {
    margin: 18px 0px !important;
  }

  .post-details-content blockquote:not(.tiktok-embed):not([data-text-post-permalink^="https://www.threads.net/"]) p {
    padding: 6px 0px;
    margin: 12px 0px !important;
  }
  .post-details-content blockquote:not(.tiktok-embed):not([data-text-post-permalink^="https://www.threads.net/"]):before
{
  bottom: -25px !important;
}
  .home-section .image-gradient:after,
  .home-section .smooth-image-gradient:after {
    content: unset;
  }
}
.image-gradient:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, black 5%, rgba(37, 51, 61, 0) 95%);
}
.smooth-image-gradient:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000de 0%, rgba(0, 0, 0, 0) 65%);
}

.image-gradient,
.smooth-image-gradient {
    position: relative;
    display: block;
}

.post-details-content figure {
    margin: 10px 0px;
}
.post-details-content figcaption {
    color: #9ca3af;
    font-size: 15px;
    line-height: 22px;
    margin-top: 8px;
    font-family: "Amine Bold";
}
.post-details-content img {
    margin-bottom: 8px;
}
.static-content strong {
    color: var(--primary-color);
}
.static-content a {
    color: #0000ee;
    text-decoration: underline;
}
.static-content em {
    color: #3ebcb0;
    font-size: 17px;
}
@media (max-width: 767px) {
    .post-details-content img {
        width: 100%;
    }
    .rhap_volume-controls {
        display: none !important;
    }
    .rhap_controls-section {
        justify-content: center !important;
    }
    .rhap_additional-controls {
        flex: unset !important;
    }
}
@media (max-width: 998px) {
    .active-menu-item::before {
        content: "";
        position: absolute;
        bottom: 50%;
        left: 0%;
        transform: translateY(50%);
        width: 4px;
        height: 50%;
        background-color: var(--primary-color);
        opacity: 1;
        visibility: visible;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
}

.circle:before,
.circle:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid var(--custom-pink);
    border-radius: 50%;
}

/* .my-custom-class {
  background-color: #ffeb3b;
  color: #333;
}
@media (min-width: 1145px) {
  .group:hover .apply-on-group-hover {
    @apply my-custom-class;
  }
} */
@media (min-width: 1280px) and (max-width: 1535px) {
    .container {
        max-width: 1255px;
    }
}
@media (min-width: 767px) and (max-width: 1024px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 1190px) and (max-width: 1280px) {
    .container {
        max-width: 1160px;
    }
}
.aside-gradients {
    position: relative;
    z-index: 0;
}

.aside-gradients::before,
.aside-gradients::after {
  content: "";
  position: absolute;
  top: 0;
  width: 38%;
  height: 100%;
  z-index: 2;
}
input#default-search {
  -webkit-appearance: none;
}
.inset-0 {
  left: 0;
  top: 0;
}
.aside-gradients::before {
  right: -1px;
  background: linear-gradient(to left, #ffffffe6, #ffffff00);
}

.aside-gradients::after {
  left: -1px;
  background: linear-gradient(to right, #ffffffe6, #ffffff00);
}

.aside-gradients .swiper-slide:not(.swiper-slide-active) {
    opacity: 0.7;
}

@media (min-width: 1536px) {

  .container {
    max-width: 1436px;
  }
}
@font-face {
  font-family: "Droid Arabic Naskh";
  font-style: normal;
  font-weight: 400;
  src: url("//fonts.gstatic.com/ea/droidarabicnaskh/v7/DroidNaskh-Regular.eot");
  src: url("//fonts.gstatic.com/ea/droidarabicnaskh/v7/DroidNaskh-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("//fonts.gstatic.com/ea/droidarabicnaskh/v7/DroidNaskh-Regular.woff2")
      format("woff2"),
    url("//fonts.gstatic.com/ea/droidarabicnaskh/v7/DroidNaskh-Regular.woff")
      format("woff"),
    url("//fonts.gstatic.com/ea/droidarabicnaskh/v7/DroidNaskh-Regular.ttf")
      format("truetype");
}


.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose],[class~=not-prose] *)):before,
.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose],[class~=not-prose] *)):before
{
    content: unset
}